var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.boutique)?_c('div',{staticClass:"store-details-page-container"},[_c('div',{staticClass:"store-details-page-header"},[_c('div',{staticClass:"store-details-bk",style:({ backgroundImage: 'url('+ _vm.boutique.imageUrl +')'})}),_c('div',{staticClass:"aqz-container"},[_c('div',{staticClass:"intro-title"},[_c('h1',{staticClass:"cl-white title boutique-name"},[_vm._v(_vm._s(_vm.boutique.name))])])])]),_c('div',{staticClass:"aqz-container store-details-page-content"},[_c('div',{staticClass:"store-details-page-info"},[_c('no-ssr',[_c('b-row',{staticClass:"justify-content-center store-location"},[_c('b-col',{staticClass:"store-location__info",attrs:{"md":"6"}},[_c('card-boutique',{staticClass:"store-location__info_store",attrs:{"boutiqueInfo":_vm.boutique,"viewImage":false,"viewDetail":false,"viewMap":false,"viewWorkingHours":true}}),_c('div',{staticClass:"store-location__info_description"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.boutique.description)}})])],1),_c('b-col',{staticClass:"store-location__map",attrs:{"md":"6"}},[_c('div',{staticClass:"d-inline-block w-100"},[_c('GmapMap',{staticClass:"full-map",attrs:{"center":{lat: parseFloat(_vm.boutique.latitude), lng: parseFloat(_vm.boutique.logintude)},"zoom":_vm.zoom,"options":{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                  styles: _vm.mapStyle
                }}},[_c('GmapMarker',{attrs:{"position":{lat: parseFloat(_vm.boutique.latitude), lng: parseFloat(_vm.boutique.logintude)},"clickable":false,"draggable":false,"icon":{ url: require('@/theme/assets/image/pin.svg'), scaledSize: {width: 60, height: 80}}}})],1),_c('div',{staticClass:"cta-direction"},[(_vm.calcRouteUrl)?_c('a',{staticClass:"bcm-link bcm-link-primary",attrs:{"href":_vm.calcRouteUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('direction')))]):_vm._e()])],1)])],1)],1)],1)]),_c('div',{staticClass:"store-page-mage"},[_c('div',{directives:[{name:"append-html",rawName:"v-append-html",value:(_vm.pageContent),expression:"pageContent"}]})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }