<template>
  <div class="card-boutique">
    <div class="card-boutique__media">
      <image-gallery
      v-if="viewImage"
      :imgPath="boutiqueUrl"
      size="MD"
      class="img-fluid"
      :changeAble="true"
      :styleImage="{ height: 'auto', width: '100%' }"/>
    </div>
    <div class="card-block card-boutique__title">
      <h3 class="title">{{boutiqueInfo.name}}</h3>
    </div>
    <div class="card-block card-boutique__description">
      <div class="description-line street-name">
        <span class="text">{{boutiqueInfo.address}}, {{boutiqueInfo.postcode}} {{boutiqueInfo.region}}, {{boutiqueInfo.country}}</span>
      </div>
      <div class="description-line phone-number line-with-label" v-show="boutiqueInfo.phone">
        <!-- <span class="label">{{ $t('phone') }}</span> -->
        <span class="text">{{boutiqueInfo.phone}}</span>
      </div>
      <div class="description-line email line-with-label" v-show="boutiqueInfo.email">
        <!-- <span class="label">{{ $t('email') }}</span> -->
        <span class="text">{{boutiqueInfo.email}}</span>
      </div>
    </div>
    <div class="card-block card-boutique__working-hours" v-if="boutiqueInfo.working_hours && viewWorkingHours">
      <div class="wh-list" v-for="(wh, index) in boutiqueInfo.working_hours" :key="index">
        <span class="label">{{ $t(index) }} </span>
        <span class="wh" v-if="wh.off === '1'"><b>{{$t('Closed')}}</b></span>
        <span class="wh" v-else>{{wh.from}} - {{wh.to}}</span>
      </div>
    </div>
    <div class="card-block card-boutique__cta">
      <router-link :to="{ name: 'Boutique', params: {store_code: boutiqueInfo.code}}"  class="bcm-link bcm-link-primary" v-if="viewDetail">{{ $t('view-detail') }}</router-link>
      <a href="#" class="bcm-link bcm-link-primary" v-if="viewMap" @click.prevent="openModalBoutique">{{ $t('view-map') }}</a>
      <slot name="footer-ctas"></slot>
    </div>
  </div>
</template>

<script>
import ImageGallery from '@/theme/components/Product/Image.vue'
import { resizeService } from '@/helpers/utils'
import Config from '@/config'

export default {
  name: 'CardBoutique',
  props: {
    boutiqueInfo: {
      type: Object | Array,
      required: true
    },
    viewImage: {
      type: Boolean,
      default: true
    },
    viewDetail: {
      type: Boolean,
      default: true
    },
    viewMap: {
      type: Boolean,
      default: true
    },
    viewWorkingHours: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calcRouteUrl () {
      if (this.boutiqueInfo) {
        return `//maps.google.com/maps?saddr=current+location&daddr=${this.boutiqueInfo.country},${this.boutiqueInfo.region},${this.boutiqueInfo.city},${this.boutiqueInfo.address}`
      }
      return null
    },
    boutiqueUrl () {
      const { width, height, quality } = Config.Theme.boutiqueOpt.imageCrop
      return `${resizeService(this.boutiqueInfo.imageUrl)}?crop=true&width=${width}&height=${height}&q=${quality}`
    }
  },
  methods: {
    openModalBoutique () {
      return this.$emit('viewMap', this.boutiqueInfo)
    }
  },
  components: {
    ImageGallery
  }
}
</script>
